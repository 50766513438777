// extracted by mini-css-extract-plugin
export var BackToMenu = "index-module--BackToMenu--17d56";
export var BackToMenuCard = "index-module--BackToMenuCard--51d2f";
export var Clickable = "index-module--Clickable--33e99";
export var DesktopGutters = "index-module--DesktopGutters--bc430";
export var ItemActionsWrapper = "index-module--ItemActionsWrapper--efa8c";
export var ItemCarusel = "index-module--ItemCarusel--44147";
export var ItemImage = "index-module--ItemImage--b677d";
export var Loader = "index-module--Loader--e9f7c";
export var PriceLine = "index-module--PriceLine--7d6ef";
export var SubTotal = "index-module--SubTotal--1a3de";
export var Upsale = "index-module--Upsale--1db14";