import _ from "lodash";
import moment from "moment";
import React from "react";
import localeDates from "../../utils/localeDates";
import { getParams } from "../../utils/location";
import AppContainer from "../AppContainer";
import Button from "../Button";
import Card from "../Card";
import EmptyState from "../EmptyState";
import List from "../List";
import Loader from "../Loader";
import OrderItem from "../OrderItem";
import PriceLine, { Price } from "../PriceLine";
import * as styles from "./index.module.scss";

const paymentTypeToLabel = (paymentType, payment) => {
  const mapping = {
    HistoryStampOpenChargeItem: "{{ business_name }} Card",
    HistoryStampOneTimePaymentItem: "Credit Card",
    HistoryStampCashPaymentItem: "Cash",
    HistoryStampOnLocationPaymentItem: "In Store Payment",
  };
  if (
    paymentType === "HistoryStampOnLocationPaymentItem" &&
    _.get(payment, "giftCardNumber")
  ) {
    return "Gift Card";
  }
  return _.get(mapping, paymentType);
};

const normalizeOrderItems = (orderItems) => {
  const normalizedOrderItems = _.map(orderItems, (item) => {
    const { configuredMenuItemOrderData } = item;

    const title = configuredMenuItemOrderData.itemName;

    const orderItemComponents = _.map(
      _.filter(
        configuredMenuItemOrderData.orderItemComponents,
        "chosenOrderItemComponentOptions",
      ),
      (orderItemComponent) => ({
        title: orderItemComponent.title,
        selectedOptions: _.map(
          _.filter(
            _.map(orderItemComponent.chosenOrderItemComponentOptions, (id) =>
              _.find(orderItemComponent.componentOptions, { id }),
            ),
            (option) => option !== undefined,
          ),
          (option) => ({
            ...option,
            price: option.price,
          }),
        ),
      }),
    );

    const toppingsCategories =
      configuredMenuItemOrderData.toppingsComponent &&
      _.map(
        configuredMenuItemOrderData.toppingsComponent
          .toppingsComponentCategories,
        (toppingCategory) => {
          return {
            title: toppingCategory.title,
            toppings: _.filter(
              toppingCategory.toppings,
              (topping) => !!_.find(topping.toppingOptions, "chosen"),
            ),
          };
        },
      );

    return {
      menuItemId: configuredMenuItemOrderData.menuItemId,
      title,
      orderItemComponents,
      toppingsCategories,
      ingredients: configuredMenuItemOrderData.ingredients,
      imageKey: configuredMenuItemOrderData.imageKey,
      imagePreview: configuredMenuItemOrderData.imagePreview,
      price: item.price,
      count: item.count,
      remark: item.remark,
    };
  });

  return normalizedOrderItems;
};

export default class HistoryView extends React.Component {
  state = { showDetailsForItemId: null };
  componentDidMount() {
    this.props.loadHistory();
  }

  itemClicked = (paymentId) => () => {
    this.props.loadPurchase(paymentId);
    this.setState((state) => {
      if (state.showDetailsForItemId === paymentId) {
        return { showDetailsForItemId: null };
      }
      return { showDetailsForItemId: paymentId };
    });
  };

  render() {
    const {
      appStyles,
      pageContext: {
        branches,
        business: { currencySymbol },
        servingOptions,
      },
      location,
      user,
      T,
    } = this.props;

    this.params = getParams(location);

    if (user.history.loading) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn>
            <Loader appStyles={appStyles} classNames={styles.Loader} />
            <strong>{T("Loading your history...")}</strong>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (user.history.error) {
      return (
        <AppContainer.Content tightBottom appStyles={appStyles}>
          <AppContainer.CenteredColumn style={{ marginTop: "45%" }}>
            <h4 style={{ color: appStyles.accentColor }}>
              {T("Could not load your history :(")}
            </h4>
            <Button appStyles={appStyles} onClick={this.props.loadHistory}>
              {T("Try Again")}
            </Button>
          </AppContainer.CenteredColumn>
        </AppContainer.Content>
      );
    }

    if (
      !_.get(user, "history.data") ||
      _.isEmpty(_.get(user, "history.data"))
    ) {
      return (
        <EmptyState
          appStyles={appStyles}
          T={T}
          description={T("You have no orders yet")}
          shouldNavigateToServingOptions={servingOptions.length < 2}
          onClick={this.props.startNewOrder}
          disableAction={_.includes(appStyles.disabledFeatures, "order")}
        />
      );
    }

    const { purchasesById } = user;

    return (
      <AppContainer.Content
        tightBottom
        appStyles={appStyles}
        classNames={styles.DesktopGutters}
      >
        <List style={{ padding: 0 }}>
          {_.map(
            _.filter(
              this.props.user.history.data,
              (purchaseEvent) =>
                purchaseEvent.openChargeCardInstanceLoadId ||
                purchaseEvent.openChargeCardPaymentId,
            ),
            (instance, index) =>
              instance.openChargeCardInstanceLoadId ? (
                <Card
                  appStyles={appStyles}
                  key={index}
                  classNames={styles.Clickable}
                >
                  <Card.Title small spread appStyles={appStyles}>
                    <span>
                      {_.capitalize(T("loaded to card"))}:{" "}
                      {Price({
                        value: _.get(instance, "amountLoaded.amount"),
                        currencySymbol,
                      })}
                    </span>
                    <span
                      style={{
                        textDecoration: instance.wasCanceled
                          ? "line-through"
                          : "none",
                      }}
                    >
                      <small style={{ fontSize: 12 }}>{T("Paid")}: </small>
                      {Price({
                        value: _.get(instance, "pricePaid.amount"),
                        currencySymbol,
                      })}
                    </span>
                  </Card.Title>
                  <Card.Content>
                    <small>
                      {moment(instance.date).format(
                        `MMM DD, YYYY, dddd ${
                          localeDates[appStyles.locale || "en-US"].hourFormat
                        }`,
                      )}
                    </small>
                  </Card.Content>
                </Card>
              ) : (
                <Card
                  appStyles={appStyles}
                  key={index}
                  classNames={styles.Clickable}
                  onClick={this.itemClicked(instance.openChargeCardPaymentId)}
                >
                  <Card.Title small spread appStyles={appStyles}>
                    <span>
                      {instance.wasCanceled && T("Canceled")}{" "}
                      {instance.type === "HistoryStampOnLocationPaymentItem"
                        ? `${T("Scan at")} `
                        : `${T("Order from")} `}
                      {instance.branchName}
                    </span>
                    <span
                      style={{
                        textDecoration: instance.wasCanceled
                          ? "line-through"
                          : "none",
                      }}
                    >
                      {Price({
                        value:
                          instance.amountPaid && instance.amountPaid.amount,
                        currencySymbol,
                      })}
                    </span>
                  </Card.Title>
                  {!_.isEmpty(instance.innerPayments) && (
                    <Card.Content>
                      {_.map(instance.innerPayments, (payment, indx) => (
                        <div
                          key={indx}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            fontVariantNumeric: "tabular-nums",
                          }}
                        >
                          <span>
                            {T(paymentTypeToLabel(payment.type, payment))}:
                          </span>
                          <span
                            style={{
                              textDecoration: payment.wasCanceled
                                ? "line-through"
                                : "none",
                            }}
                          >
                            {Price({
                              value: _.get(payment, "amountPaid.amount", 0),
                              currencySymbol,
                            })}
                          </span>
                        </div>
                      ))}
                    </Card.Content>
                  )}
                  <Card.Content>
                    <small>
                      {moment(instance.date).format(
                        `MMM DD, YYYY, dddd ${
                          localeDates[appStyles.locale || "en-US"].hourFormat
                        }`,
                      )}
                    </small>
                  </Card.Content>
                  {purchasesById[instance.openChargeCardPaymentId] &&
                    this.state.showDetailsForItemId ===
                      instance.openChargeCardPaymentId && (
                      <div>
                        {purchasesById[instance.openChargeCardPaymentId]
                          .loading ? (
                          <Card.Content>
                            <AppContainer.CenteredColumn>
                              <Loader small appStyles={appStyles} />
                            </AppContainer.CenteredColumn>
                          </Card.Content>
                        ) : purchasesById[instance.openChargeCardPaymentId]
                            .error ? (
                          <Card.Content>
                            {T("Try Reopening this order...")}
                          </Card.Content>
                        ) : (
                          <div>
                            <Card.Title
                              small
                              appStyles={appStyles}
                              style={{ marginTop: 8 }}
                            >
                              {T("Order Details")}
                              {purchasesById[instance.openChargeCardPaymentId]
                                .data.transactionId && (
                                <span style={{ paddingLeft: 10 }}>
                                  {`(#${purchasesById[
                                    instance.openChargeCardPaymentId
                                  ].data.transactionId.slice(-8)})`}
                                </span>
                              )}
                            </Card.Title>
                            <Card.Content>
                              {_.map(
                                normalizeOrderItems(
                                  purchasesById[
                                    instance.openChargeCardPaymentId
                                  ].data.orderItems,
                                ),
                                (item) => (
                                  <OrderItem
                                    hideImage
                                    appStyles={appStyles}
                                    key={item.id}
                                    item={item}
                                    T={T}
                                    currencySymbol={currencySymbol}
                                    light
                                  />
                                ),
                              )}

                              {purchasesById[instance.openChargeCardPaymentId]
                                .data.discountItems.length > 0 && (
                                <div>
                                  <Card.Title
                                    small
                                    appStyles={appStyles}
                                    style={{ marginTop: 8 }}
                                  >
                                    {T("Rewards")}
                                  </Card.Title>
                                  {_.map(
                                    purchasesById[
                                      instance.openChargeCardPaymentId
                                    ].data.discountItems,
                                    (item, index) => (
                                      <div
                                        key={index}
                                        style={{
                                          marginTop: 8,
                                          display: "flex",
                                          justifyContent: "space-between",
                                        }}
                                      >
                                        <strong>{item.description} </strong>
                                        <strong>
                                          {Price({
                                            value: item.discountAmount,
                                            currencySymbol,
                                            discount: true,
                                          })}
                                        </strong>
                                      </div>
                                    ),
                                  )}
                                  <div
                                    style={{
                                      marginTop: 8,
                                      borderTop: "1px solid #aaa",
                                    }}
                                  />
                                </div>
                              )}
                            </Card.Content>
                            <div>
                              {_.map(
                                _.keys(
                                  purchasesById[
                                    instance.openChargeCardPaymentId
                                  ].data.detailedPrice,
                                ),
                                (priceField) => (
                                  <PriceLine
                                    key={priceField}
                                    capitalizeLabel
                                    label={T(priceField)}
                                    subtotal={priceField === "subtotal"}
                                    currencySymbol={currencySymbol}
                                    value={
                                      purchasesById[
                                        instance.openChargeCardPaymentId
                                      ].data.detailedPrice[priceField]
                                    }
                                  />
                                ),
                              )}
                            </div>
                            {instance.lastFourDigits && (
                              <div>
                                <Card.Title
                                  small
                                  appStyles={appStyles}
                                  style={{ marginTop: 8 }}
                                >
                                  {T("Payment Method")}
                                </Card.Title>
                                <Card.Content>
                                  <strong
                                    style={{
                                      marginTop: 8,
                                      display: "flex",
                                      justifyContent: "space-between",
                                    }}
                                  >
                                    <span>{T("Credit Card")}</span>
                                    <span>**** {instance.lastFourDigits}</span>
                                  </strong>
                                </Card.Content>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                    )}
                </Card>
              ),
          )}
        </List>
      </AppContainer.Content>
    );
  }
}
