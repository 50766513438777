import React from "react";
import { connect } from "react-redux";
import withTranslation from "../hocs/withTranslation";
import HistoryView from "../components/HistoryView";
import { loadHistory, loadPurchase } from "../store/user/actions";
import { getStyles } from "../store/selectors";
import { startNewOrder } from "../store/order/actions";

const HistoryPage = (props) => <HistoryView {...props} />;

const mapStateToProps = (state, props) => {
  const { user, header, purchasesById } = state;

  return {
    appStyles: getStyles(state, props),
    user,
    header,
    purchasesById,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    loadHistory: () => dispatch(loadHistory()),
    loadPurchase: (paymentId) => dispatch(loadPurchase(paymentId)),
    startNewOrder: () => dispatch(startNewOrder()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTranslation(HistoryView));
