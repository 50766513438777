import classnames from "classnames";
import React from "react";
import * as styles from "./index.module.scss";

export const Price = ({ value, currencySymbol, discount, rtl }) => {
  return !isNaN(value) && value != null ? (
    rtl ? (
      <span>
        {currencySymbol}
        {discount ? Math.abs(value).toFixed(2) : value.toFixed(2)}
        {discount && "-"}
      </span>
    ) : (
      <span>
        {discount && "-"}
        {currencySymbol}
        {discount ? Math.abs(value).toFixed(2) : value.toFixed(2)}
      </span>
    )
  ) : (
    <span />
  );
};

export default ({
  label,
  value,
  currencySymbol,
  subtotal,
  discount,
  capitalizeLabel,
  rtl,
  receiptPrice,
  className,
}) => (
  <div
    className={classnames(
      styles.PriceLine,
      subtotal && styles.SubTotal,
      capitalizeLabel && styles.CapitalizeLabel,
      receiptPrice && styles.ReceiptPrice,
      className,
    )}
  >
    <label
      style={
        receiptPrice && { ...(rtl ? { marginLeft: 4 } : { marginRight: 4 }) }
      }
    >
      {label}
    </label>
    <Price
      discount={discount}
      value={discount ? Math.abs(value) : value}
      currencySymbol={currencySymbol}
      rtl={rtl}
    />
  </div>
);
